import { AppBar, Box, Button, Container, Link, Toolbar } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';

import React from 'react';
import { useStyles } from './styles';

export default function Header() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar}>
          <Box className={classes.nav}>
            {/* <Box className={classes.logo}>
              <a href="/">miura</a>
            </Box> */}
            <ul className={classes.navList}>
              <li>
                <a href="https://www.miura.ie">Home</a>
              </li>
              <li>
                <a href="https://www.miura.ie/about-miura-2/">About Miura</a>
              </li>
              <li>
                <a href="https://www.miura.ie/contact-us/">Contact Us</a>
              </li>
            </ul>
          </Box>
          <Box className={classes.authMenu}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/auth/sign-in');
              }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
