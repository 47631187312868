import Button from '@material-ui/core/Button';
import React from 'react';
import { palette } from 'theme/palette';
import { withStyles } from '@material-ui/core/styles';

const LinkButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: '16px',
    weight: 'bold',
    textDecoration: 'underline',
    textTransform: 'none',
    color: palette.text.primary,
    '&:hover': {
      color: palette.primary.main,
    },
  },
})(Button);

function LinkButtonComponent({ children, onClick }) {
  return (
    <LinkButton onClick={onClick} disableRipple disableFocusRipple>
      {children}
    </LinkButton>
  );
}

export default LinkButtonComponent;
