import { AUTH_LOGOUT, AUTH_REFRESH_TOKEN, PUBLIC_ENDPOINTS } from 'config/endpoints';

import axios from 'axios';
import { injectStringParams } from 'utils/string';
import { rootStore } from 'stores';

/*
This was created solely for the purposes of testing some of the Auth
functionality with a seperate (non-miura )Auth backend
This can be removed at any time. Leaving in place for now while Auth development pending.
*/

const { REACT_APP_API_AUTH_URL } = process.env;

const httpAuthClient = axios.create({
  baseURL: REACT_APP_API_AUTH_URL,
  timeout: 30 * 1000, // 30 sec
});

httpAuthClient.interceptors.request.use((config) => {
  if (!PUBLIC_ENDPOINTS.includes(config.url) && rootStore.user.id_token) {
    config.headers.Authorization = `Bearer ${rootStore.user.id_token}`;
  }
  if (config.requestParams) {
    config.url = injectStringParams(config.url, config.requestParams);
  }
  if(config?.data?.domain) {
    config.baseURL = config.data.domain;
  }
  if(config?.domain) {
    config.baseURL = config.domain;
  }
  return config;
});

httpAuthClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error?.response?.status === 401) {
      const excludedRoute = [AUTH_REFRESH_TOKEN, AUTH_LOGOUT].includes(error.config.url);
      if (!excludedRoute && !error.config.reprocessed) {
        error.config.reprocessed = true;
        return rootStore.user
          .refreshToken(rootStore.user.refresh_token)
          .then(({ data }) => {
            error.config.headers.Authorization = `Bearer ${data.id_token}`;
            return httpAuthClient.request(error.config);
          })
          .catch(() => {
            return rootStore.user.removeAuthInfo();
          });
      } else {
        rootStore.user.removeAuthInfo();
      }
    }
    return Promise.reject(error);
  },
);

export default httpAuthClient;
