import React, { useEffect, useState } from 'react';

import DocumentItem from 'shared/DocumentItem';

const DocumentIntro = (props) => {
  useEffect(() => {}, []);

  const renderOverviewDetail = () => {
    return (
      <>
        <div className="section-title">The document is broken into 2 Sections:</div>
        <ol>
          <li>
            <div className="section-title">The Miura Compliance Framework Introduction</div>
            <p>
              This section describes the overall Comprehensive Framework which the firm uses to ensure that this Compliance Micro-process
              is in a state of either Operational Control or Regulatory Compliance, and how this document fits as part of it
            </p>
          </li>
          <li>
            <div className="section-title">Information Specific to this Compliance Micro-process</div>
            <p>
              Lists the Regulatory Rule or Operational process that this Compliance Micro-process:
              {props.businessProcess} addresses and details how the firm ensures that this Compliance Micro-process
              is in a state of either Operational Control or Regulatory Compliance.
            </p>
          </li>
        </ol>
      </>
    );
  };

  const renderCoreCharateristics = () => {
    return (
      <>
        <div className="section-title">The document has 3 core characteristics:</div>
        <ol>
          <li>
            <p>
              It is a core part of the Miura Compliance Framework however it has sufficient
              information in it that it can be audited in isolation.
            </p>
          </li>
          <li>
            <p>
              It is auto-created by the Miura Regulatory Portal, based on information extracted from
              the Regulatory Rule Sentence or Operational process using our NLP Engine. This information
              is then converted into business information which forms the basis of our documentation set.
            </p>
          </li>
          <li>
            <p>
              It is dynamic, so that if any Regulatory Rules change, or the Clients Business Model
              changes in any way, the document set is automatically deleted and replaced by the new Compliance Micro-process
              derived from the new replacement Regulatory Rule Sentence or Operational Micro-process.
            </p>
          </li>
        </ol>
      </>
    );
  };

  const renderOverview = () => {
    return (
      <>
        <p>
          This document sets out how the firm executes the Compliance Micro-process: "{' '}
          {props.businessProcess}" and how the firm ensures that the process is in a stage of
          Operational Control or Regulatory Compliance.
        </p>
        {renderOverviewDetail()}
        {renderCoreCharateristics()}
      </>
    );
  };

  const renderIntroductionDetail = () => {
    return (
      <>
        <div className="section-title">Aims of a Compliance Framework</div>
        <p>
          The objective of a Compliance Framework is to ensure that the firm adheres to the law,
          including the spirit of the law, while ensuring the compliance risks are identified and
          adequately mitigated. In order to create a Regulatory Compliance Framework that can be 
          automatically updated to refect changes in the regulation or changes in the business,
          Miura creates Regulatory Compliance Framework at the Regulator Rule Sentence Level. 
          Each Regulation Rule Sentence is converted into a Compliance Micro-process using our NLP
          Engine. The system then builds a comprehensive Compliance Framework around this Compliance 
          Micro-process, including a Risk Assessment, Policy Document, Procedures Document, Evidence
          Framework and 1st Line of defence (Control) and Compliance Monitoring Dashboards (2nd Line of
          defence). Our Automated Compliance Testing Module Scans each of the documents on a daily basis 
          to ensure they continue to address the rules. And the system ensures that the Compliance 
          Framework complies with the 92 points in the ESMA Compliance Guidellines 2020 (ESMA35-36-1946).
        </p>
        <p>The firms achieves regulatory compliance using the following Compliance Framework:</p>

        <div className="section-title">Risk Assessment</div>
        <p>
          As per the ESMA Compliance Guidelines (5th June 2020), each firm needs to perform a Risk
          Assessment to identify the greatest risks to the business, in order to manage these risks
          with more frequent monitoring. Miura identifies, the Risks, Risk Impacts, and assigns a
          Risk Rating for each. It also identifies the applicable monitoring frequencies and the
          Risk Mitigants.
        </p>

        <div className="section-title">Policy Document</div>
        <p>
          The firms Policy Document outlines the framework in place for how the firm firstly
          identifies and extracts the business information from each Regulatory Rule Sentence rule and then
          details how the firm ensures compliance with these monitoring points in each applicable
          rule, covering 1st, 2nd and 3rd lines of defence. Each Compliance Micro-process contains
          a Policy Document specific to that Micro-process.
        </p>
        <div className="section-title">Procedures Document</div>
        <p>
          The firms procedures document outlines the process that the firm goes through to execute each
          Compliance Micro-process. This includes, ntify the key monitoring points within those rules, understand
          the risks and rating them and then drawing up an output evidence report to prove that the
          firm is complying with the rule. These procedures are outlined on a generic rule-by-rule
          basis, and where relevant, supplemented with client specific procedures for client
          specific elements of the procedure – e.g. how the firm creates it’s first line of defence.
        </p>

        <div className="section-title">Evidence File/Report</div>
        <p>
          The evidence documentation is the evidence that the firm continues to comply with the
          monitoring points in each applicable Rule (e.g. A BCP test could be an Evidence that there
          is a BCP Framework in place). This is the proof that the first line of defence is
          operating well.
        </p>
        <div className="section-title">Control Report</div>
        <p>
          This Framework has an in-built control framework, where the 1st line business function
          owner will be sent a control report to complete on a timely basis for them to validate
          that they are complying with all the relevant rules.
        </p>
        <div className="section-title">Compliance Testing</div>
        <p>
          As part of the second line of defence, Compliance will need to validate that some of the
          documentation is fit-for-purpose and is being followed. Some of this process can be
          automated on the portal. Different testing frameworks will work better with different
          rules, so the framework can support all types. POLICY: Policy testing can be tracked by
          simply monitoring to see if the overall framework is being followed. PROCEDURES: Employees
          can screen record them following procedures and save them up to the system to prove that
          they are following the procedures correctly. These can be reviewed remotely by compliance.
          CONTROLS: The validation of controls can be completed by sense checking them against the
          corresponding evidence documents. The control report is the declaration that the operator
          is adhering to the rules and the evidence is the proof. EVIDENCE: The Compliance
          Department can test a specific evidence document to ensure is complies with the rules and
          this can be saved to the evidence folder once completed. This can be automated by checking
          the Evidence documents for a specific set of keywords, which would ensure it is fit for
          purpose (e.g. testing the quality of a firms BCP procedures can be easily done by scanning
          the document for the list of the firms systems to ensure that the procedure is
          comprehensive).
        </p>
        <div className="section-title">Compliance Monitoring</div>
        <p>
          As a second line of defence, the firm will need to monitor that this Regulatory Compliance
          Framework is in place. Daily scanning happens of the firms Policies, Procedures, Controls
          and Evidence documentation to 1. Ensuring they are present, and 2. To ensure they are
          addressing the rules. Assuming the documentation formats have been signed off initially,
          and ensuring the dates the documents have been uploaded are recent,{' '}
          {props.businessProcessOwner} can deduct that the Compliance Framework is in a state of
          control.
        </p>
        <div className="section-title">Audit</div>
        <p>
          Audit is the 3rd line of defence and the firms policy is that the firms Auditors will have
          access to all the information set out in the Compliance Framework . Their role is as an
          independent check to ensure that the firm has adequately functioning first and second
          lines of defence and to conduct independent checks to ensure the documentation & controls
          are fit for purpose. They have access to all the Policies & Procedures Documentation &
          Controls, Compliance Monitoring Reports and test results for each rule and they can
          independently examine any part of the Compliance Framework (policies, procedures,
          controls, evidences). They are then efficiently able to complete one-click sign-off on top
          of the monitoring data that has gone before, by compliance.
        </p>
        <div className="section-title">Regulator Audit</div>
        <p>
          Regulators are sometimes referred to as the 4th line of defence. Their role is as an
          independent check to ensure that the firm has adequately functioning first and second
          lines & third lines of defence and to conduct independent checks to ensure the
          documentation & controls are fit for purpose. They have access to all the Policies &
          Procedures Documentation & Controls, Compliance Monitoring Reports and test results for
          each rule and they can independently examine any part of the Compliance Framework
          (policies, procedures, controls, evidences). They are then efficiently able to complete
          one-click sign-off on top of the monitoring data that has gone before, by Compliance and
          Audit.
        </p>
      </>
    );
  };

  return (
    <>
      <DocumentItem title="Overview" body={renderOverview()} />
      <DocumentItem
        title="Miura Compliance Framework Introduction"
        body={renderIntroductionDetail()}
      />
    </>
  );
};

DocumentIntro.defaultProps = {};

export default DocumentIntro;
