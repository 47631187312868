export const palette = {
  common: {
    white: '#fff',
    black: '#000',
  },
  text: {
    primary: '#010101',
    secondary: '#000',
    // disabled: '#E8E8E8',
    hint: '#010101',
  },
  background: {
    main: '#fff',
    default: '#fff',
    secondary: '#252B42',
  },
  primary: {
    main: '#ce1011',
  },
  secondary: {
    main: '#010101',
  },
  error: {
    main: '#FF505F',
  },
  warning: {
    main: '#FF9800',
  },
  info: {
    main: '#007DFF',
  },
  success: {
    main: '#4CAF50',
  },
  status: {
    not_started: '#787878',
    in_progress: '#2cb8a0',
    completed: '#0d78e799',
    completed_dark: '#3477ba',
    review: '#0d78e799',
    rejected: '#FF505F',
    rejected_dark: '#cc0011',
    accepted: '#3fa443d4',
    accepted_dark: '#389108',
    submitted: '#ffb347',
    submitted_dark: '#d6963e',
    cleared: '#3fa443d4',
    cleared_dark: '#389108',
    exception: '#2E7D32',
  },
};
