import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import { useHistory } from 'react-router-dom';
import {useStore} from "../../hooks";
import {useSnackbar} from "notistack";
import * as config from "config/constants";

const RegChangeManagement = () => {
  const history = useHistory();
  const [microRegulatoryProcessNext, setMicroRegulatoryProcessNext] = useState('');
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [microRegulatoryProcessCount, setMicroRegulatoryProcessCount] = useState(0);
  const [microRegulatoryProcessDisplayCount, setMicroRegulatoryProcessDisplayCount] = useState(0);
  const [microRegulatoryProcessLoading, setMicroRegulatoryProcessLoading] = useState(false);
  const store = useStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {}, []);
  const numRecordPage = config.pageSize;

  const getMicroRegulatoryProcessList = async () => {
    try {
      const { data } = await store.api.getMicroProcessesRead();
      setMicroRegulatoryProcessData(data.results);
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessCount(data.count);
      data.count > numRecordPage
        ? setMicroRegulatoryProcessDisplayCount(numRecordPage)
        : setMicroRegulatoryProcessDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };
 const getNextMicroRegulatoryProcesses = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const { data } = await store.api.getNextDataWithURL(microRegulatoryProcessNext);
      let newMicroRegulatoryProcesses = microRegulatoryProcessData;
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        newMicroRegulatoryProcesses.push(process);
        if (arrayLength == i + 1) {
          setMicroRegulatoryProcessData(newMicroRegulatoryProcesses);
          setMicroRegulatoryProcessDisplayCount(microRegulatoryProcessDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setMicroRegulatoryProcessLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

  useEffect(() => {
    getMicroRegulatoryProcessList();
  }, []);

  const navigateToMicroRegulatoryProcess = (microProcessId) => {
    const path = 'user-micro-regulatory-process-detail?micro_process='+microProcessId;
    history.push(path);
  };


  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <SwapHorizontalCircleOutlinedIcon
        style={{
          width: '42px',
          height: '42px',
          color: '#e0123c',
          fontSize: 'large',
          position: 'absolute',
        }}
      />
      <h4>Regulations and Operations Change Management Dashboard</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
        <div>
        <div>This screen lists all Regulatory Micro-processes that have been assigned a process owner but have not completed their first Compliance Monitoring Programme as so are therefore not live.</div>
        <div>&nbsp;</div>
        <div><strong>ACTION</strong>:&nbsp; Process Owners will be responsible for Reviewing and Signing off the Risk Assessment, Policy, Procedures documents, uploading the applicable Evidence Files/Reports and completing the first Compliance Monitoring Programme.&nbsp;</div>
        <div><br clear="all" />
        <div><strong>RESULT</strong>: The execution of the first Compliance Monitoring Programme will result in the Regulatory Micro-process "GOING LIVE". This means it will be part of the on-going Compliance Monitoring and appear in the Micro-process owner's Management Dashboard for approval, and the scheduled Next Review Date. The Next Reviewed Date is calculated from the date the process finished it's last Compliance Monitoring Programme, taking into account the Next Review Date Frequency (e.g. Monthly).</div>
        </div>
        </div>
        <p>&nbsp;</p>
            <span>
            Displaying {microRegulatoryProcessDisplayCount} of {microRegulatoryProcessCount} Micro
            Regulatory Processes
          </span>
        </li>
                    {microRegulatoryProcessData !== undefined &&
          microRegulatoryProcessData.map((microProcess, i) => {
            const microProcessId = microProcess.id;

            return (
              <li key={i} onClick={() => navigateToMicroRegulatoryProcess(microProcessId)}>
                <span>{microProcessId}</span>
                <span className="title-spacing">{microProcess.micro_regulatory_process.name}</span>
                <div className="status-text">
                  <span className="owner"> Owner: {microProcess.owner.first_name} {microProcess.owner.last_name}</span>
                    <span className="last-updated">Last Updated At {microProcess.last_updated}</span>
                </div>
                {microProcess.validation_status == 'Passed' ? (
                  <div className="status green-status"></div>
                ) : (
                  <div className="status red-status"></div>
                )}
              </li>
            );
          })}
        {microRegulatoryProcessCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextMicroRegulatoryProcesses()}>
            <span>{microRegulatoryProcessLoading ? 'Loading...' :  'Load ' + numRecordPage.toString() + ' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};
RegChangeManagement.defaultProps = {};

export default RegChangeManagement;
