export const typography = {
  fontSize: 14,
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  h1: {
    fontWeight: 700,
    fontSize: 56,
    letterSpacing: 1.5,
    marginBottom: 40,
  },
  h2: {
    fontWeight: 700,
    fontSize: 40,
    letterSpacing: 1,
    lineHeight: '32px',
    marginBottom: 30,
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px',
    lineHeight: '28px',
    marginBottom: 20,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
    lineHeight: '24px',
    marginBottom: 15,
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    marginBottom: 10,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    marginBottom: 10,
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0',
    lineHeight: '21px',
  },
};
