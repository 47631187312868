import { useContext } from 'react';
import { StoreContext } from 'stores';

export function useStore() {
  const store = useContext(StoreContext);
  if (store === null) {
    throw new Error('No stores provider found.');
  }
  return store;
}
