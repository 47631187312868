import React from 'react';

const ComplianceText = (props) => {
  const formatText = (text) => {
    const newText = text
      .toString()
      .split('\n')
      .map((str, i) => <p key={i}>{str}</p>);
    return newText;
  };

  return <>{formatText(props.text)}</>;
};

ComplianceText.defaultProps = {};
export default ComplianceText;
