export const drawerClosedWidth = 56;
export const drawerWidth = 257;
export const pageSize = 100;
export const domain1 = 'https://insuranceone.stg-api.miura.ie/api/v1';
export const domain2 = 'https://kinsalecapitalaif.stg-api.miura.ie/api/v1';
export const domain3 = 'https://kinsalecapitalmifid.stg-api.miura.ie/api/v1';
export const domains = {
  domain1 : 'https://insuranceone.stg-api.miura.ie/api/v1'
};
const dashboardLinks = {
  domain1: {
    '10 Training': '/business-process?business=8&business_process=99&business_function=25',
    '11 Compliance Reporting': '/business-process?business=8&business_process=79&business_function=25',
    '12 Regulatory Reporting': '/business-process?business=8&business_process=84&business_function=25',
    '13 Audit': '/business-process?business=8&business_process=78&business_function=24',
    '14 Communication with the Regulator': '/business-process?business=8&business_process=82&business_function=25'
  },
  domain2: {
    '10 Training': '/business-process?business=8&business_process=99&business_function=25',
    '11 Compliance Reporting': '/business-process?business=8&business_process=79&business_function=25',
    '12 Regulatory Reporting': '/business-process?business=8&business_process=84&business_function=25',
    '13 Audit': '/business-process?business=8&business_process=78&business_function=24',
    '14 Communication with the Regulator': '/business-process?business=8&business_process=82&business_function=25'
  },
  domain3: {
    '10 Training': '/business-process?business=9&business_process=99&business_function=25',
    '11 Compliance Reporting': '/business-process?business=9&business_process=79&business_function=25',
    '12 Regulatory Reporting': '/business-process?business=9&business_process=84&business_function=25',
    '13 Audit': '/business-process?business=2&business_process=10&business_function=4',
    '14 Communication with the Regulator': '/business-process?business=9&business_process=82&business_function=25'
  }
};

export { dashboardLinks };
export const multiDomain = true;
