import './control-table.scss';

import * as api from 'config/endpoints';

import React, { useEffect, useState } from 'react';
import { getAbsoluteURL, patchData } from 'services/api-utils';

import Moment from 'moment';
import ProcessDetail from 'shared/ProcessDetail';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

const ControlTable = (props) => {
  const [processPopupActive, setProcessPopupActive] = useState(false);
  const [toggledProcessUrl, setToggledProcessUrl] = useState('');
  const [toggledLifecycleProcessId, setToggledLifecycleProcessId] = useState('');
  const [toggledLifecycleStage, setToggledLifecycleStage] = useState('');
  const [updatingId, setUpdatingId] = useState('');
  const [updatingCommentId, setUpdatingCommentId] = useState('');
  const [focusedCommentId, setFocusedCommentId] = useState('');

  useEffect(() => {}, []);

  const updateProcessPopupContentValue = async (processId, commentData) => {
    setUpdatingCommentId(processId);
    commentData = commentData.replace(/[\r\n\v]+/g, '');

    const data = {
      comment: commentData,
    };

    const patchRuleEndpoint = `${api.CONTROL_PROCESS_API_ENDPOINT}${processId}/`;
    await patchData(patchRuleEndpoint, data);
    await props.triggerReloadContentNoReset();
    setTimeout(async () => {
      setFocusedCommentId('');
      setUpdatingCommentId('');
    }, 1000);
  };

  const updateFocusedCommentId = (processId) => {
    setFocusedCommentId(processId);
  };

  const updateSignOffStatus = async (processId, controlReportStatus) => {
    if (updatingId === '') {
      setUpdatingId(processId);
      const status =
        controlReportStatus === 'Awaiting Sign-Off' ? 'Signed-Off' : 'Awaiting Sign-Off';
      const data = {
        control_report_status: status,
      };
      const patchRuleEndpoint = `${api.CONTROL_PROCESS_API_ENDPOINT}${processId}/`;
      await patchData(patchRuleEndpoint, data);
      await props.triggerReloadContentNoReset();
      setTimeout(async () => {
        setUpdatingId('');
      }, 1000);
    }
  };

  const toggleRuleDocumentDetailsPopup = (lifecycleProcessNumber, lifecycleStage) => {
    let sectionEndpoint = '';
    if (lifecycleStage === 'Risk') {
      sectionEndpoint = api.RISK_PROCESS_API_ENDPOINT;
    }
    if (lifecycleStage === 'Policy') {
      sectionEndpoint = api.POLICY_PROCESS_API_ENDPOINT;
    }
    if (lifecycleStage === 'Procedure') {
      sectionEndpoint = api.PROCEDURE_PROCESS_API_ENDPOINT;
    }
    if (lifecycleStage === 'Evidence') {
      sectionEndpoint = api.EVIDENCE_PROCESS_API_ENDPOINT;
    }

    setToggledLifecycleProcessId(lifecycleProcessNumber ? lifecycleProcessNumber : '');
    let absoluteURL = getAbsoluteURL(`${sectionEndpoint}${lifecycleProcessNumber}/`);

    lifecycleProcessNumber && sectionEndpoint !== ''
      ? setToggledProcessUrl(absoluteURL)
      : setToggledProcessUrl('');

    lifecycleStage ? setToggledLifecycleStage(lifecycleStage) : setToggledLifecycleStage('');

    setProcessPopupActive(!processPopupActive);
  };

  const tempCommentsData = [];

  return (
    <>
      {processPopupActive && (
        <ProcessDetail
          lifecycleProcessUrl={toggledProcessUrl}
          lifecycleProcessId={toggledLifecycleProcessId}
          microProcessId={props.microRegulatoryProcessData.id}
          lifecycleStage={toggledLifecycleStage}
          closePopup={() => toggleRuleDocumentDetailsPopup()}
          triggerReloadContent={() => props.triggerReloadContent()}
        />
      )}
      <Table className="control-table">
        <TableHead>
          <TableRow key="control-table">
            <TableCell style={{ maxWidth: 200 }}>Reg Doc</TableCell>
            <TableCell style={{ width: 120 }}>Rule Number</TableCell>
            <TableCell style={{ width: 70 }}>Risk</TableCell>
            <TableCell style={{ width: 70 }}>Policy</TableCell>
            <TableCell style={{ width: 70 }}>Procedure</TableCell>
            <TableCell style={{ width: 70 }}>Evidence</TableCell>
            <TableCell style={{ width: 130 }}>Sign Off Status</TableCell>
            <TableCell style={{ width: 200 }}>Approved By</TableCell>
            <TableCell style={{ width: 230 }}>Comments</TableCell>
            <TableCell style={{ width: 90 }}>Control Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.controlProcesses !== undefined &&
            props.controlProcesses.map((controProcess, i) => {
              const signedOff = controProcess.control_report_status === 'Signed-Off';
              const docRule =
                controProcess.company_micro_regulatory_process.micro_regulatory_process
                  .regulation_document_sub_rule.regulation_document_rule;

              const riskStatus = controProcess.risk_validation_status;
              const policyStatus = controProcess.policy_validation_status;
              const procedureStatus = controProcess.procedure_validation_status;
              const evidenceStatus = controProcess.evidence_validation_status;

              tempCommentsData.push(controProcess.comment);

              return (
                <TableRow key={i}>
                  <TableCell style={{ maxWidth: 200 }}>
                    {
                      docRule.regulation_document_rule_group.regulation_document_section
                        .regulation_document.name
                    }
                  </TableCell>
                  <TableCell style={{ width: 120 }}>{docRule.rule_number}</TableCell>
                  <TableCell
                    style={{ width: 70 }}
                    className={`rule-status-item`}
                    onClick={() => toggleRuleDocumentDetailsPopup(controProcess.id, 'Risk')}
                  >
                    <span className={riskStatus === 'Failed' ? 'failed' : 'passed'}>
                      {riskStatus}
                    </span>
                  </TableCell>
                  <TableCell
                    style={{ width: 70 }}
                    className={`rule-status-item`}
                    onClick={() => toggleRuleDocumentDetailsPopup(controProcess.id, 'Policy')}
                  >
                    <span className={policyStatus === 'Failed' ? 'failed' : 'passed'}>
                      {policyStatus}
                    </span>
                  </TableCell>
                  <TableCell
                    style={{ width: 70 }}
                    className={`rule-status-item`}
                    onClick={() => toggleRuleDocumentDetailsPopup(controProcess.id, 'Procedure')}
                  >
                    <span className={procedureStatus === 'Failed' ? 'failed' : 'passed'}>
                      {procedureStatus}
                    </span>
                  </TableCell>
                  <TableCell
                    style={{ width: 70 }}
                    className={`rule-status-item`}
                    onClick={() => toggleRuleDocumentDetailsPopup(controProcess.id, 'Evidence')}
                  >
                    <span className={evidenceStatus === 'Failed' ? 'failed' : 'passed'}>
                      {evidenceStatus}
                    </span>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      updateSignOffStatus(controProcess.id, controProcess.control_report_status)
                    }
                    style={{ width: 130 }}
                    className={`rule-status-item sign-off-button`}
                  >
                    {updatingId === controProcess.id && (
                      <div className="loading-tab">
                        <div className="loading-icon"></div>
                      </div>
                    )}
                    {updatingId !== controProcess.id && (
                      <div
                        className={`status-button ${
                          signedOff ? 'signed-off' : 'awaiting-sign-off'
                        }`}
                      >
                        {signedOff ? 'SIGNED OFF' : 'SIGN OFF?'}
                      </div>
                    )}
                  </TableCell>
                  <TableCell style={{ width: 200 }}>
                    {controProcess.last_reviewed_by !== null &&
                    controProcess.last_reviewed_by.username
                      ? controProcess.last_reviewed_by.username
                      : '—'}
                    <br />
                    {Moment(controProcess.last_reviewed).format('MMM Do YYYY, h:mm a')}
                  </TableCell>
                  <TableCell style={{ width: 230 }}>
                    {updatingCommentId !== controProcess.id && (
                      <>
                        <TextField
                          className="input-text-comment"
                          type="text"
                          variant="outlined"
                          defaultValue={tempCommentsData[i]}
                          multiline
                          spellCheck={false}
                          rows={3}
                          onFocus={() => updateFocusedCommentId(controProcess.id)}
                          onBlur={() => setFocusedCommentId('')}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              updateProcessPopupContentValue(controProcess.id, e.target.value);
                            }
                          }}
                        />
                        {focusedCommentId === controProcess.id && (
                          <div
                            style={{
                              fontSize: 9,
                              width: '100%',
                              textAlign: 'center',
                              paddingTop: 2,
                              marginBottom: -16,
                            }}
                          >
                            Press ENTER to save comment
                          </div>
                        )}
                      </>
                    )}
                    {updatingCommentId === controProcess.id && <div className="loading-icon"></div>}
                  </TableCell>
                  <TableCell style={{ width: 90 }} className={`rule-status-item`}>
                    <span
                      className={controProcess.validation_status === 'Failed' ? 'failed' : 'passed'}
                    >
                      {controProcess.validation_status}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

ControlTable.defaultProps = {};

export default ControlTable;
