import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { useStore } from 'hooks';
import FolderIcon from '@material-ui/icons/Folder';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  listItemText:{
    cursor: "pointer",
    color: '#2980B9'
  },
}));



export default function FolderList({filesList, isFolder=true, onClick}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.demo}>
            <List>
              {filesList && filesList.map((file,i) =>{
                  const filenameId = file.path;
                  let nameList = '';
                  if (!isFolder){
                    nameList = file.split('/');}
                  return (<ListItem key={i} onClick={() => isFolder ? onClick(filenameId, i) : onClick(file)}>
                    {isFolder && (<ListItemIcon>
                              <FolderIcon />
                            </ListItemIcon>)}

                  <ListItemText
                    primary={isFolder ? file.folder : nameList[nameList.length - 1]} className={classes.listItemText}
                  />
                  </ListItem>)
                  })}

            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
