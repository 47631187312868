import { Cell, Pie, PieChart } from 'recharts';
import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import {
  AreaChart,
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import {useHistory} from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";


const data = [
  {
    name: "1/1/2023",
    uv: 250000000,
    pv: (250000000*0.6),
    amt: 2400
  },
  {
    name: "3/5",
    uv: (250000000*0.5),
    pv: 250000000,
    amt: 2210
  },
  {
    name: "3/10",
    uv: 250000000,
    pv: (250000000*0.6),
    amt: 2290
  },
  {
    name: "3/15",
    uv: (250000000*0.4),
    pv: 250000000,
    amt: 2000
  },
  {
    name: "3/20",
    uv: 250000000,
    pv: (250000000*0.6),
    amt: 2181
  },
  {
    name: "3/25",
    uv: (250000000*0.6),
    pv: 250000000,
    amt: 2500
  },
  {
    name: "3/31/2023",
    uv: 261009465,
    pv: 250000000,
    amt: 2100
  }
];
const COLORS = ['#16b67b', '#e0123c', '#ffc73a', '#B2B2B2'];
const stocks = [
  {id: 1, name: 'Alice', country: 'Austria'},
  {id: 2, name: 'Bob', country: 'Belgium'},
  {id: 3, name: 'Carl', country: 'Canada'},
];
const STATUS_COLOR = {
  PASSED: '#16b67b',
  FAILED: '#e0123c',
  UNDERWAY: '#ffc73a',
  SCHEDULED: '#B2B2B2',
};

const   DashboardManagement = (props) => {
  const [metricsData, setMetricsData] = useState();
  const [opsControlsMetricsData, setOpsControlsMetricsData] = useState();
  const [activeChartHoverTitle, setActiveChartHoverTitle] = useState();
  const [activeChartHoverTitleControl, setActiveChartHoverTitleControl] = useState();
  const [activeChartHoverMetric, setActiveChartHoverMetric] = useState();
  const [activeChartHoverMetricControl, setActiveChartHoverMetricControl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date());

  const [chartData, setChartData] = useState([
    { name: 'Passed', value: 25 },
    { name: 'Failed', value: 25 },
    { name: 'Scheduled', value: 25 },
    { name: 'Underway', value: 25 },
  ]);

  const [opsControlsChartData, setOpsControlsChartData] = useState([
    { name: 'In Control', value: 50 },
    { name: 'Not In Control', value: 50 },
  ]);

  const getMetrics = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getComplianceFramework('1');
      setMetricsData(data);
    } catch (error) {
      console.log('An error occured loading metrics ' + error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getOperationControlMetrics = async () => {
    try {
      const { data } = await store.api.getOperationalControlFramework('1');
      setOpsControlsMetricsData(data);
    } catch (error) {
      console.log('An error occured loading metrics ' + error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    getMetrics();
    getOperationControlMetrics();
  }, []);

  useEffect(() => {
    metricsData &&
      setChartData([
        { name: 'Passed', value: Math.floor(metricsData.percentage_passed) },
        { name: 'Failed', value: Math.ceil(metricsData.percentage_failed) },
        {
          name: 'Underway',
          value: Math.floor(metricsData.percentage_underway),
        },
        {
          name: 'Scheduled',
          value: Math.floor(metricsData.percentage_scheduled),
        },
      ]);
    metricsData && setActiveChartHoverTitle('Passed');
    metricsData && setActiveChartHoverMetric(Math.floor(metricsData.percentage_passed));
  }, [metricsData]);

  useEffect(() => {
    opsControlsMetricsData &&
      setOpsControlsChartData([
        {
          name: 'In Control',
          value: Math.floor(opsControlsMetricsData.percentage_in_control),
        },
        {
          name: 'Not In Control',
          value: Math.floor(opsControlsMetricsData.percentage_not_in_control),
        },
      ]);
    opsControlsMetricsData && setActiveChartHoverTitleControl('In Control');
    opsControlsMetricsData &&
      setActiveChartHoverMetricControl(Math.floor(opsControlsMetricsData.percentage_in_control));
  }, [opsControlsMetricsData]);

  const chartHover = (e) => {
    setActiveChartHoverTitle(e.payload.name);
    setActiveChartHoverMetric(e.payload.value);
  };
  const chartHoverControl = (e) => {
    setActiveChartHoverTitleControl(e.payload.name);
    setActiveChartHoverMetricControl(e.payload.value);
  };
  const formatDate = (date) => {
    const d = new Date(date);
    let day = '' + d.getDate(),
        month = '' + (d.getMonth() + 1), // Months are zero-based
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');

  };
  const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };
  const handleSearch = () => {
    // Your search logic here
    console.log('Search button clicked!');
};

  return (
    <section id={`home-container`}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>
       Real-Time Board Report
      </h4>
     <ul className="categories-list" style={{ backgroundColor: '#f4f5f7' }}>
          <div>
          <div>
          <div>&nbsp;</div>
          </div>
          </div>
          <p>&nbsp;</p>
     </ul>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="flex-end">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
           <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </MuiPickersUtilsProvider>
      <div>
        <h3>Investment Management</h3>
        <div style={{ backgroundColor: '#f4f5f7' }}>&nbsp;&nbsp;</div>
        <h5 style={{ 'font-size': '18px' }}>Fund Valuation</h5>
        {metricsData && (
          <div className="table-container">
            <div className="card-holder-horizontal">
              <div className="card">
                <span>Ops Process</span>
                <span className="text">AIFM_OPS_426_VALUE INVESTMENT FUND</span>
              </div>
              <div className="card">
                <span>Data Element</span>
                  <span className="text">NAV</span>
              </div>
              <div className="card">
                <span>Start Date Value</span>
                <span className="text">251.002.321</span>
              </div>
              <div className="card">
                <ComposedChart width={500} height={125} data={data}
                  margin={{ top: 30, right: 40, left: 20, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#f70000" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#f70000" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#c5c5c5" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="##8b8989" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#8b8989" fillOpacity={1} fill="url(#colorUv)" />
                  <Area type="monotone" dataKey="pv" stroke="#c5c5c5" fillOpacity={1} fill="url(#colorPv)" />
                  <Line type="monotone" dataKey="uv" stroke="#8b8989" />
                  <Line type="monotone" dataKey="pv" stroke="#c5c5c5" />
                </ComposedChart>
              </div>
              <div className="card" >
                <span>Start Date Value</span>
                <span className="text">251.002.321</span>
              </div>
            </div>

          </div>
        )}

        <h2>VALUE FUND POSITIONS</h2>
        {
          stocks.map((stoke, index) => {
           return (
                <div className="table-container">
                  <div className="card-holder-horizontal">
                    <div className="card">
                      <span>Stock {index}</span>
                      <span className="metric">0</span>
                    </div>

                    <div className="card">
                      <span>Positions</span>
                      <span className="metric">-</span>
                    </div>
                    <div className="card">
                    </div>

                   <div className="card">

                    <AreaChart
                      width={500}
                      height={120}
                      data={data}
                      margin={{
                        top: 10,
                        right: 40,
                        left: 20,
                        bottom: 0
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" fill="#f0f0f0" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area type="monotone" dataKey="uv" stroke="#f80202" fill="#f80202" />
                    </AreaChart>
                  </div>
                  </div>
              </div>
            );
          })}




        <h3 style={{ fontSize: '20px', marginTop: '20px' }}>
          CALCULATE FUND P&L
        </h3>

      </div>
    </section>
  );
};

export default DashboardManagement;
