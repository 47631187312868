import { Container, Typography } from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function Loader() {
  const classes = useStyles();

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Typography variant="h1" gutterBottom align="center" className={classes.h1}>
        Loading
      </Typography>
      <LinearProgress />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '300px',
  },
  h1: {
    color: '#252B42',
  },
}));
