import React, { createContext } from 'react';

import { UIStore } from 'stores/UIStore';
import { UserStore } from 'stores/UserStore';
import api from 'services/api';
import { makeAutoObservable } from 'mobx';

class RootStore {
  loading = false;

  constructor() {
    makeAutoObservable(this);

    this.ui = new UIStore(this);
    this.user = new UserStore(this);
    this.api = api;
  }

  // fetch all required data after a successful login
  *fetch() {
    try {
      this.loading = true;
      yield this.user.getActiveUser();
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}

export const rootStore = new RootStore();

export const StoreContext = createContext(null);
export const StoreProvider = ({ children }) => {
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};

StoreContext.displayName = 'Mobx Root Store';
