import { Box, Container } from '@material-ui/core';

import CopyrightIcon from '@material-ui/icons/Copyright';
import { NavLink } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

export default function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box className={classes.navigationHolder}>
          <Box className={classes.navigationSection}>
            <ul className={classes.navigation}>
              <li>
                <a href="https://www.miura.ie">Home</a>
              </li>
              <li>
                <a href="https://www.miura.ie/about-miura-2/">About Miura</a>
              </li>
              <li>
                <a href="https://www.miura.ie/contact-us/">Contact Us</a>
              </li>
            </ul>
          </Box>
        </Box>
        <Box className={classes.copyright}>
          <CopyrightIcon style={{ marginRight: 8 }} />
          <Typography variant="body1" style={{ fontSize: 12 }}>
            miura 2021 - All rights reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
