import React, { useEffect, useState } from 'react';

import Loader from 'routes/Root/Loader';
import { useStore } from 'hooks';

function Root({ children }) {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await store.fetch();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // Commenting out for now as needs to be styled
  // if (isLoading) {
  //   return <Loader />;
  // }

  return children;
}

export default Root;
