import './micro-regulatory-process-nav.scss';

import React, { useEffect, useState } from 'react';

const MicroRegulatoryProcessNav = (props) => {
  let [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  const tabUpdate = (tabName) => {
    setActiveTab(tabName);
    props.updateTab(tabName);
  };

  return (
    <ul className="compliance-category-nav">
      <li className={activeTab === 'Risk' ? 'active' : ''} onClick={() => tabUpdate('Risk')}>
        Risk
      </li>
      <li className={activeTab === 'Policy' ? 'active' : ''} onClick={() => tabUpdate('Policy')}>
        Policy
      </li>
      <li
        className={activeTab === 'Procedure' ? 'active' : ''}
        onClick={() => tabUpdate('Procedure')}
      >
        Procedure
      </li>
      <li
        className={activeTab === 'Evidence' ? 'active' : ''}
        onClick={() => tabUpdate('Evidence')}
      >
        Evidence
      </li>
      <li className={activeTab === 'Control' ? 'active' : ''} onClick={() => tabUpdate('Control')}>
        Control
      </li>
      {/* <li className={activeTab === 'testing' ? 'active' : ''} onClick={() => tabUpdate('testing')}>Testing</li> */}
      <li
        className={activeTab === 'Monitoring' ? 'active' : ''}
        onClick={() => tabUpdate('Monitoring')}
      >
        Monitoring
      </li>
      {/* <li className={activeTab === 'audit' ? 'active' : ''} onClick={() => tabUpdate('audit')}>Audit</li>
      <li className={activeTab === 'regulator-audit' ? 'active' : ''} onClick={() => tabUpdate('regulator-audit')}>Regulator Audit</li> */}
    </ul>
  );
};

MicroRegulatoryProcessNav.defaultProps = {};

export default MicroRegulatoryProcessNav;
