import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from 'hooks';

function PrivateRoute({ component: Component, children, ...restProps }) {
  const store = useStore();

  if (!store.user.isAuthorized) {
    return <Redirect to="/auth/sign-in" />;
  }

  return <Route render={props => <Component {...props} children={children} />} {...restProps} />;
}

export default observer(PrivateRoute);
