import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  padding: {
    marginLeft: '32px',
  },
  icon: {
    height: '58px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
  },
}));

function Field({ children, icon }) {
  const classes = useStyles();
  const Icon = icon;

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems="flex-start">
      {icon && (
        <Grid item className={classes.icon}>
          <Icon color="disabled" />
        </Grid>
      )}
      <Grid container item className={clsx({ [classes.padding]: !icon })}>
        {children}
      </Grid>
    </Grid>
  );
}

export default Field;
