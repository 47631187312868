import './document-item.scss';

import React, { useEffect, useState } from 'react';

const DocumentItem = (props) => {
  const [toggleItemClosed, setToggleItemClosed] = useState(true);

  useEffect(() => {}, []);

  const toggleItem = () => {
    setToggleItemClosed(!toggleItemClosed);
  };

  return (
    <div className="document-item">
      <h4>
        {props.title}
        <div
          className={`close-button ${toggleItemClosed ? '' : 'active'}`}
          onClick={() => toggleItem()}
        ></div>
      </h4>
      <div className="content-body">{!toggleItemClosed && props.body}</div>
    </div>
  );
};

DocumentItem.defaultProps = {};

export default DocumentItem;
