import { Redirect, Route, Switch } from 'react-router-dom';

import ForgotPassword from 'routes/Auth/ForgotPassword';
import React from 'react';
import ResetPassword from 'routes/Auth/ResetPassword';
import SignIn from 'routes/Auth/SignIn';

function Auth() {
  return (
    <Switch>
      <Route path="/auth/sign-in" component={SignIn} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/auth/reset-password" component={ResetPassword} />
      <Redirect to="/auth/sign-in" />
    </Switch>
  );
}

export default Auth;
