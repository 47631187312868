import './process-table.scss';

import React, { useEffect, useState } from 'react';

import ProcessDetail from 'shared/ProcessDetail';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const ProcessTable = (props) => {
  const [rulesPopupActive, setProcessDetailActive] = useState(false);
  const [toggledRuleUrl, setToggledRuleUrl] = useState('');
  const [toggledRuleId, setToggledRuleId] = useState('');

  useEffect(() => {}, []);

  const toggleProcessDetail = (lifecycleProcess) => {
    lifecycleProcess
      ? setToggledRuleId(
          lifecycleProcess.company_micro_regulatory_process.id
            ? lifecycleProcess.company_micro_regulatory_process.id
            : '',
        )
      : setToggledRuleUrl('');
    lifecycleProcess ? setToggledRuleUrl(lifecycleProcess.url) : setToggledRuleUrl('');
    setProcessDetailActive(!rulesPopupActive);
  };

  return (
    <>
      {rulesPopupActive && (
        <ProcessDetail
          lifecycleProcessUrl={toggledRuleUrl}
          lifecycleProcessId={toggledRuleId}
          microProcessId={props.microProcessId}
          ruleDocType={props.ruleDocType}
          closePopup={() => toggleProcessDetail()}
          triggerReloadContent={() => props.triggerReloadContent()}
        />
      )}
      <Table className="rules-table">
        <TableHead>
          <TableRow key="rules-table">
            <TableCell style={{ width: 90 }}>Regulator</TableCell>
            <TableCell style={{ width: 90 }}>Regulation</TableCell>
            <TableCell style={{ minWidth: 160 }}>Document</TableCell>
            <TableCell style={{ minWidth: 160 }}>Rule Title</TableCell>
            <TableCell style={{ width: 90 }}>Rule Number</TableCell>
            <TableCell style={{ width: 90 }}>Review Status</TableCell>
            <TableCell style={{ width: 90 }}>Validation Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.microRegulatoryProcessItems !== undefined &&
            props.microRegulatoryProcessItems.map((lifecycleProcess, i) => {
              const group =
                lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
                  .regulation_document_sub_rule.regulation_document_rule
                  .regulation_document_rule_group;
              return (
                <TableRow key={i} onClick={() => toggleProcessDetail(lifecycleProcess)}>
                  <TableCell>
                    {
                      group.regulation_document_section.regulation_document.regulation.regulator
                        .name
                    }
                  </TableCell>
                  <TableCell>
                    {group.regulation_document_section.regulation_document.regulation.name}
                  </TableCell>
                  <TableCell>
                    {group.regulation_document_section.regulation_document.name}
                  </TableCell>
                  <TableCell>
                    {
                      lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
                        .regulation_document_sub_rule.regulation_document_rule.rule_title
                    }
                  </TableCell>
                  <TableCell>
                    {
                      lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
                        .regulation_document_sub_rule.sub_rule_number
                    }
                  </TableCell>
                  <TableCell>
                    {lifecycleProcess.review_status === 'Issue' && (
                      <span className={'status-item failed'}>{lifecycleProcess.review_status}</span>
                    )}
                    {lifecycleProcess.review_status === 'Backlog' && (
                      <span className={'status-item pending'}>
                        {lifecycleProcess.review_status}
                      </span>
                    )}
                    {lifecycleProcess.review_status === 'Underway' && (
                      <span className={'status-item pending'}>
                        {lifecycleProcess.review_status}
                      </span>
                    )}
                    {lifecycleProcess.review_status === 'Ready' && (
                      <span className={'status-item passed'}>{lifecycleProcess.review_status}</span>
                    )}
                    {lifecycleProcess.review_status === 'Reviewed' && (
                      <span className={'status-item passed'}>{lifecycleProcess.review_status}</span>
                    )}
                    {lifecycleProcess.review_status === 'Audited' && (
                      <span className={'status-item passed'}>{lifecycleProcess.review_status}</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {lifecycleProcess.validation_status === 'Failed' && (
                      <span className={'status-item failed'}>
                        {lifecycleProcess.validation_status}
                      </span>
                    )}
                    {(lifecycleProcess.validation_status === 'Underway' ||
                      lifecycleProcess.validation_status === 'Backlog') && (
                      <span className={'status-item pending'}>
                        {lifecycleProcess.validation_status}
                      </span>
                    )}
                    {(lifecycleProcess.validation_status === 'Passed' ||
                      lifecycleProcess.validation_status === 'Ready') && (
                      <span className={'status-item passed'}>
                        {lifecycleProcess.validation_status}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

ProcessTable.defaultProps = {};

export default ProcessTable;
