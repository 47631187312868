import { Box } from '@material-ui/core';
import CopyrightIcon from '@material-ui/icons/Copyright';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.copyright}>
        <CopyrightIcon style={{ marginRight: 8 }} />
        <Typography variant="body1" style={{ fontSize: 12 }}>
          2021 copyright miura
        </Typography>
      </Box>
    </Box>
  );
}

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'column',
    background: '#252B42',
    width: '100%',
    // height: '100%',
    // position: 'absolute',
    bottom: 0,
    left: 0,
    // marginBottom: '-100px',
    // paddingBottom: '100px',
  },
  copyright: {
    padding: spacing(2),
    color: '#fff',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    height: 56,
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'flex-end',
  },
}));
