import './side-panel-card-item.scss';

import React, { useEffect, useState } from 'react';

const SidePanelCardItem = (props) => {
  useEffect(() => {}, []);

  return (
    <div
      className={`side-panel-card-item ${props.canScroll ? 'apply-scroll' : ''}`}
      style={{
        height: props.height,
        marginBottom: props.marginBottom,
        marginRight: props.marginRight,
      }}
    >
      {props.children}
    </div>
  );
};

SidePanelCardItem.defaultProps = {};

export default SidePanelCardItem;
