import React, { useCallback } from 'react';
import { drawerClosedWidth, drawerWidth } from 'config/constants';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Menu from 'layouts/PrivateLayout/Drawer/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MuiDrawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStore } from 'hooks';

function Drawer() {
  const classes = useStyles();
  const store = useStore();

  const toggleDrawer = useCallback(() => {
    store.ui.drawerOpen = !store.ui.drawerOpen;
  }, []);

  return (
    <MuiDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: store.ui.drawerOpen,
        [classes.drawerClose]: !store.ui.drawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: store.ui.drawerOpen,
          [classes.drawerClose]: !store.ui.drawerOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={toggleDrawer}>
          {store.ui.drawerOpen ? (
            <CloseIcon style={{ color: '#fff' }} />
          ) : (
            <MenuIcon style={{ color: '#fff' }} />
          )}
        </IconButton>
      </div>
      <Menu />
    </MuiDrawer>
  );
}

export default observer(Drawer);

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerClosedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerClosedWidth,
  },
  drawerPaper: {
    color: '#fff',
    backgroundColor: '#2E445B',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 4px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
