import { makeAutoObservable } from 'mobx';

export class UIStore {
  drawerOpen = false;

  constructor(root) {
    this.root = root;
    makeAutoObservable(this, { root: false });
  }
}
