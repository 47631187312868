import { Box, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';

import SignInForm from 'routes/Auth/SignIn/SignInForm';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const SignIn = () => {
  const classes = useStyles();
  const store = useStore();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async (credentials) => {
    try {
      await store.user.login(credentials);
      enqueueSnackbar('Success!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Oops, something went wrong!', {
        variant: 'error',
      });
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Typography variant="h1" align="center" color="primary">
        miura regulatory compliance
      </Typography>
      <Typography variant="h5" className={classes.description} align="center">
        100% Compliance Assurance In Real-Time
      </Typography>
      <Typography variant="h2" align="center">
        Sign In
      </Typography>
      <SignInForm onSubmit={handleSubmit} />
    </Box>
  );
};

export default observer(SignIn);

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
    margin: 'auto 0',
  },
  description: {
    width: '750px',
    margin: '0 auto 40px',
    fontWeight: 400,
    letterSpacing: 1,
  },
}));
