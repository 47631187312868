import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'hooks';

function PublicRoute({ layout: Layout = ({ children }) => children, ...restProps }) {
  const store = useStore();
  const isVerificationScreen = useRouteMatch('/auth/applicant/register');

  if (store.user.isChallenge && !isVerificationScreen) {
    return <Redirect to="/auth/applicant/register" />;
  }

  if (store.user.isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Route {...restProps} />
    </Layout>
  );
}

export default observer(PublicRoute);
