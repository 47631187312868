import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.css';
import 'assets/styles/app.scss';

import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import Slide from '@material-ui/core/Slide';
import { SnackbarProvider } from 'notistack';
import { StoreProvider } from 'stores';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import theme from 'theme';

function App() {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          autoHideDuration={1000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Slide}
        >
          <ToastContainer />
          <Router>
            <Routes />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
