import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStore } from 'hooks';

function Header({ className }) {
  const classes = useStyles();
  const store = useStore();

  return (
    <AppBar position="fixed" className={className}>
      <Toolbar className={classes.header}>
        {/* <Box className={classes.logo}>
          <a href="/">miura</a>
        </Box> */}
        <Typography variant="h3">miura</Typography>
        <Box className={classes.user}>
          <PersonOutlineIcon color="primary" />
          <Typography variant="h5" noWrap>
            {store.user.fullName}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default observer(Header);

const useStyles = makeStyles(({ palette }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    margin: '0 125px 0 0',
    '& h2': {
      margin: 0,
      '& a': {
        textDecoration: 'none',
        color: palette.text.primary,
      },
    },
  },
  user: {
    display: 'flex',
    alignItems: 'flex-end',
    '& h5': {
      margin: '0 0 0 5px',
    },
  },
}));
