import { makeStyles } from '@material-ui/core/styles';
import { palette } from 'theme/palette';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 80,
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  button: {
    marginLeft: theme.spacing(2),
    backgroundColor: palette.primary.main,
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    margin: '0 125px 0 0',
    '& h2': {
      margin: 0,
      '& a': {
        textDecoration: 'none',
        color: 'white',
      },
    },
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    fontWeight: 550,
    flexDirection: 'row',
    '& li': {
      marginRight: 44,
      '& a': {
        textDecoration: 'none',
        color: 'black',
      },
    },
  },
}));
