import { Box, Container } from '@material-ui/core';

import Footer from 'layouts/PublicLayout/Footer';
import Header from 'layouts/PublicLayout/Header';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function PublicLayout({ children }) {
  const classes = useStyles();
  return (
    <Box display="flex" flexGrow={1} flexDirection="column" pt={10}>
      <Header />
      <main className={classes.content}>
        <Container maxWidth="xl" className={classes.container}>
          {children}
        </Container>
      </main>
      <Footer />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    display: 'flex',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
