import { drawerClosedWidth, drawerWidth } from 'config/constants';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from 'layouts/PrivateLayout/Drawer';
import Footer from 'layouts/PrivateLayout/Footer';
import Header from 'layouts/PrivateLayout/Header';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStore } from 'hooks';

function PrivateLayout({ children }) {
  const classes = useStyles();
  const store = useStore();

  return (
    <div className="app">
      <div className={classes.root}>
        <CssBaseline />
        <Drawer />
        <Header className={clsx(classes.appBar, { [classes.appBarShift]: store.ui.drawerOpen })} />
        <main className={clsx(classes.content, { [classes.contentShift]: store.ui.drawerOpen })}>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default observer(PrivateLayout);

const useStyles = makeStyles(({ zIndex, transitions, spacing }) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    height: '100%',
  },
  appBar: {
    marginLeft: drawerClosedWidth,
    width: `calc(100% - ${drawerClosedWidth}px)`,
    zIndex: zIndex.drawer + 1,
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: spacing(1),
    marginLeft: drawerClosedWidth,
    width: `calc(100% - ${drawerClosedWidth}px)`,
    marginTop: 80,
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
}));
