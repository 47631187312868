import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const BusinessProcesses = (props) => {
  const [businessProcessesData, setBusinessProcessesData] = useState();
  const [businessProcessesNext, setBusinessProcessesNext] = useState('');
  const [businessProcessesCount, setBusinessProcessesCount] = useState(0);
  const [businessProcessesDisplayCount, setBusinessProcessesDisplayCount] = useState(0);
  const [businessProcessesLoading, setBusinessProcessesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const getBusinessProcesses = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinessProcesses();
      setBusinessProcessesData(data.results);
      setBusinessProcessesNext(data.next);
      setBusinessProcessesCount(data.count);
      data.count > 10
        ? setBusinessProcessesDisplayCount(10)
        : setBusinessProcessesDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinessProcesses = async () => {
    try {
      setBusinessProcessesLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessProcessesNext);
      let addNewDomains = businessProcessesData;
      setBusinessProcessesNext(data.next);
      setBusinessProcessesData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessProcessesData(addNewDomains);
          setBusinessProcessesLoading(false);
          setBusinessProcessesDisplayCount(businessProcessesDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setBusinessProcessesLoading(false);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessProcessesLoading(false);
    }
  };

  useEffect(() => {
    getBusinessProcesses();
  }, []);

  const navigateToBusinessProcess = (processId) => {
    const path = '/business-process?process=' + processId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/home')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>Business Processes</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessProcessesDisplayCount} of {businessProcessesCount} Business
            Processes
          </span>
        </li>

        {businessProcessesData !== undefined &&
          businessProcessesData.map((process, i) => {
            const processId = process.id;
            return (
              <li key={i} onClick={() => navigateToBusinessProcess(processId)}>
                <span>{processId}</span>
                <span className="title-spacing">{process.business_process.name}</span>
                <div className="status-text">
                  {/*<span className="last-updated">*/}
                  {/*  LAST UPDATED BY {process.last_updated_by.username} AT {process.last_updated}*/}
                  {/*</span>*/}
                  {/*<span className="owner">*/}
                  {/*  <b>{process.business_process.entered_by}</b> Owner*/}
                  {/*</span>*/}
                  <span className="owner"> Owner: {process.owner.first_name} {process.owner.last_name}</span>
                </div>
                <span
                  className={`applicable-status ${
                    process.applicable === 'Applicable' ? '' : 'not-applicable'
                  }`}
                >
                  {process.applicable}
                </span>
              </li>
            );
          })}
        {businessProcessesCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextBusinessProcesses()}>
            <span>{businessProcessesLoading ? 'Loading...' : 'Load 10 more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

BusinessProcesses.defaultProps = {};

export default BusinessProcesses;
