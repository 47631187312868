// TODO - The below code is a legacy implementation. All code using this should be re-factored to use httpClient.js
import Axios from 'axios';

const { REACT_APP_API_BASE_URL } = process.env;

export const getData = async (path) => {
  const domain = localStorage.getItem('domain');
  const baseURL = window.localStorage.getItem('baseURL');
  const authToken = localStorage.getItem('miura_access_token_' + domain.toString());
  return new Promise((resolve) => {
    Axios.get(`${baseURL}${path}`, {
      headers: { Authorization: 'Bearer ' + authToken },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const getAbsoluteURL = (path) => {
  const baseURL = window.localStorage.getItem('baseURL');
  return `${baseURL}${path}`;
};

export const getDataWithAbsoluteURL = async (absoluteURL) => {
  const domain = localStorage.getItem('domain');
  const authToken = localStorage.getItem('miura_access_token_' + domain.toString());
  return new Promise((resolve) => {
    Axios.get(`${absoluteURL}`, {
      headers: { Authorization: 'Bearer ' + authToken },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const putData = async (path, data) => {
  const domain = localStorage.getItem('domain');
  const authToken = localStorage.getItem('miura_access_token_' + domain.toString());
  return new Promise((resolve) => {
    Axios.put(`${REACT_APP_API_BASE_URL}${path}`, data, {
      headers: { Authorization: 'Bearer ' + authToken },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const putDataWithAbsoluteURL = async (absoluteURL, data) => {
  const domain = localStorage.getItem('domain');
  const authToken = localStorage.getItem('miura_access_token_' + domain.toString());
  return new Promise((resolve) => {
    Axios.put(`${absoluteURL}`, data, {
      headers: { Authorization: 'Bearer ' + authToken },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const patchData = async (path, data) => {
  const domain = localStorage.getItem('domain');
  const authToken = localStorage.getItem('miura_access_token_' + domain.toString());
  return new Promise((resolve) => {
    Axios.patch(`${REACT_APP_API_BASE_URL}${path}`, data, {
      headers: { Authorization: 'Bearer ' + authToken },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};
