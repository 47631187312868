import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'column',
    background: '#2e2f36',
  },
  navigationHolder: {
    padding: spacing(2),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    color: palette.common.white,
  },
  copyright: {
    padding: spacing(2),
    color: '#fff',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    height: 56,
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'flex-end',
  },
  navigationSection: {
    display: 'flex',
    flexDirection: 'column',
    '& h3': {
      margin: 0,
    },
    marginRight: spacing(5),
  },
  navigation: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& li': {
      fontSize: 16,
      fontWeight: 700,
      marginBottom: spacing(1),
      '& a': {
        color: palette.common.white,
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
}));
