import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const BusinessFunctionDetail = (props) => {
  const [activeBusinessId, setActiveBusinessId] = useState('');
  const [activeBusinessFunctionId, setActiveBusinessProcessId] = useState('');
  const [businessFunctionData, setBusinessFunctionData] = useState();
  const [businessProcessNext, setBusinessProcessNext] = useState('');
  const [businessProcessData, setBusinessProcessData] = useState();
  const [businessProcessCount, setBusinessProcessCount] = useState(0);
  const [businessProcessDisplayCount, setBusinessProcessDisplayCount] = useState(0);
  const [microprocessUnderControlCount, setMicroprocessUnderControlCount] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const getBusinessFunctionDetail = async (activeBusinessFunctionId) => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinessFunction(activeBusinessFunctionId);
      setBusinessFunctionData(data);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBusinessProcessPercentage = async (activeBusinessId) => {
    try {
      const { data } = await store.api.getBusinessProcessPercentage(activeBusinessId);
      return data;
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getBusinessProcessList = async (activeBusinessFunctionId) => {
    try {
      const { data } = await store.api.getBusinessProcessesForBusinessFunction(
        activeBusinessFunctionId,
      );
      setBusinessProcessData(data.results);
      setBusinessProcessNext(data.next);
      setBusinessProcessCount(data.count);
      data.count > 10
        ? setBusinessProcessDisplayCount(10)
        : setBusinessProcessDisplayCount(data.count);

      var dict ={};
      const promises = data.results.map(async (companyBusinessFunction, i) => {
         dict[companyBusinessFunction.id] =  await getBusinessProcessPercentage(companyBusinessFunction.id);
      });
      await Promise.all(promises);
      setMicroprocessUnderControlCount(dict);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    const params = window.location.href;
    let url = new URL(params);
    let activeBusinessId = url.searchParams.get('business');
    let activeBusinessFunctionId = url.searchParams.get('business_function');
    setActiveBusinessId(activeBusinessId);
    setActiveBusinessProcessId(activeBusinessFunctionId);
    getBusinessFunctionDetail(activeBusinessFunctionId);
    getBusinessProcessList(activeBusinessFunctionId);
  }, []);

  const navigateToBusinessProcess = (
    activeBusinessId,
    activeBusinessFunctionId,
    businessProcessId,
  ) => {
    const path =
      '/business-process' +
      '?business=' +
      activeBusinessId +
      '&business_process=' +
      businessProcessId +
      '&business_function=' +
      activeBusinessFunctionId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div
        className="back-nav"
        onClick={() => history.push('/business?business=' + activeBusinessId)}
      >
        Back to Business Functions for Business
      </div>
      <div className="header-icon"></div>
      {businessFunctionData === undefined && (
        <div className="loading-icon-container">
          <div className="loading-icon"></div>
        </div>
      )}
      <h4>
        Business Function -{' '}
        {businessFunctionData !== undefined && businessFunctionData.business_function.name}
      </h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessProcessDisplayCount} of {businessProcessCount} Business Processes
          </span>
        </li>
        {businessProcessData !== undefined &&
          businessProcessData.map((companyBusinessProcess, i) => {
            const businessProcessId = companyBusinessProcess.id;
            const percentage = microprocessUnderControlCount[businessProcessId] ? microprocessUnderControlCount[businessProcessId].percentage : 0;
            const total = microprocessUnderControlCount[businessProcessId] ? microprocessUnderControlCount[businessProcessId].total: 0;
            const under_control = microprocessUnderControlCount[businessProcessId] ? microprocessUnderControlCount[businessProcessId].under_control: 0;

            return (
              <li
                key={i}
                onClick={() =>
                  navigateToBusinessProcess(
                    activeBusinessId,
                    activeBusinessFunctionId,
                    businessProcessId,
                  )
                }
              >
                <span>{businessProcessId}</span>
                <span className="title-spacing">
                  {companyBusinessProcess.business_process.name}
                </span>
                <div className="status-text">
                  {/*<span className="last-updated">*/}
                  {/*  LAST UPDATED BY {companyBusinessProcess.last_updated_by.username}*/}
                  {/*</span>*/}
                  {/* <span className="owner">Owner: {companyBusinessProcess.owner.username}</span>*/}
                  <span className="owner"> Owner: {companyBusinessProcess.owner.first_name} {companyBusinessProcess.owner.last_name}</span>
                </div>
                {/*<span*/}
                {/*  className={`applicable-status ${*/}
                {/*    companyBusinessProcess.applicable === 'Applicable' ? '' : 'not-applicable'*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  {companyBusinessProcess.applicable}*/}
                {/*</span>*/}
                <div className="applicable-percentage">   {under_control}/{total}   </div>
                <div className="status red-percentage">
                  <div
                  className="green"
                  style={{ width: `${percentage}%` }}>
                  </div>

                </div>

              </li>
            );
          })}
      </ul>
    </section>
  );
};

BusinessFunctionDetail.defaultProps = {};

export default BusinessFunctionDetail;
