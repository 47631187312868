import './document-lifecycle-process.scss';

import React, { useEffect, useState } from 'react';

import ComplianceText from 'shared/ComplianceText';

const DocumentLifecycleProcess = (props) => {
  const [toggleItemClosed, setToggleItemClosed] = useState(true);

  useEffect(() => {}, []);

  const regulationDocumentName = () => {
    return props.lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
      .regulation_document_sub_rule.regulation_document_rule.regulation_document_rule_group
      .regulation_document_section.regulation_document.name;
  };

  const documentRuleTitle = () => {
    return props.lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
      .regulation_document_sub_rule.regulation_document_rule.rule_title;
  };

  const documentSubRuleNumber = () => {
    return props.lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
      .regulation_document_sub_rule.sub_rule_number;
  };

  const toggleItem = () => {
    setToggleItemClosed(!toggleItemClosed);
  };

  return (
    <>
      {props.lifecycleProcess && (
        <div className="document-item">
          <h4>
            {`${regulationDocumentName()} | ${documentRuleTitle()} | ${documentSubRuleNumber()}`}
            <div
              className={`close-button ${toggleItemClosed ? '' : 'active'}`}
              onClick={() => toggleItem()}
            ></div>
          </h4>
          <div className="content-body">
            {!toggleItemClosed && props.lifecycleProcess && (
              <>
                <div className="section-title">Rule content</div>
                <ComplianceText
                  text={
                    props.lifecycleProcess.company_micro_regulatory_process.micro_regulatory_process
                      .regulation_document_sub_rule.content
                      ? props.lifecycleProcess.company_micro_regulatory_process
                          .micro_regulatory_process.regulation_document_sub_rule.content
                      : 'Empty'
                  }
                />
                <div className="section-title">Reasons for applicability</div>
                <ComplianceText
                  text={
                    props.lifecycleProcess.company_micro_regulatory_process.applicability_reason
                  }
                />
                <div className="section-title">Aims of the Risk document</div>
                {props.lifecycleProcess.aims}
                <div className="section-title">Risk for rule compliance</div>
                <ComplianceText
                  text={
                    props.lifecycleProcess.dynamic_compliance_content
                      ? props.lifecycleProcess.dynamic_compliance_content
                      : 'Empty'
                  }
                />
                <div className="section-title">Firm Specific Compliance information</div>
                <ComplianceText
                  text={
                    props.lifecycleProcess.company_compliance_content
                      ? props.lifecycleProcess.company_compliance_content
                      : 'Addressed by provided rule compliance information.'
                  }
                />
                <div className="section-title">Additional Information</div>
                <ComplianceText
                  text={
                    props.lifecycleProcess.comment
                      ? props.lifecycleProcess.comment
                      : 'No additional notes provided.'
                  }
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

DocumentLifecycleProcess.defaultProps = {};

export default DocumentLifecycleProcess;
